import { UI_VERSION, DIRECT_DOMAIN, MSP_ENABLED, FALL_BACK_VERSION_ENABLED } from 'src/version';
import Registry from '@reporting-n-viz/oneapp-extensibility';
import _ from 'lodash';

const FEATURE_KEY = 'fawkes.features';
const LICENSE_KEY = 'fawkes.license';
const GPEP_KEY = 'fawkes.cloudServices'; // fetching data from /cloud-services api
const NOTIFICATIONS_KEY = 'fawkes.notifications';
const PRISMA_PAE = 'prisma_access_edition';
const PRISMA_PA = 'prisma_access';
const SCM_APP_ID = 'strata_cloud_manager';
const STRATA_INSIGHTS = 'strata_insights';
const FORM_FACTOR_TYPE_NGFW = 'ngfw';
const FORM_FACTOR_TYPE_FLEX = 'flex';
const FORM_FACTOR_TYPE_PA = 'prisma_access';
const LOGGING_SERVICE_EXTENSION_NAMES = ['Logging Service', 'logging_service', 'Strata Logging Service'];

const isSCMPremium = (tsgInstances) => {
	for (const tsgInstance of tsgInstances) {
		// 2 conditions can result in SCM premium
		// FS Link: https://confluence-dc.paloaltonetworks.com/pages/viewpage.action?pageId=294784739#SCMPremiumActivation(NGFW,PrismaAccess,VMFlex)-Howtoidentifyproducttiers?
		// Check strata_cloud_manager app id and if its entitlements have NGFW or FLEX form factor then its premium
		// Check prisma_access_edition app id and if its entitlements have prisma_access form factor then its premium
		if (tsgInstance.app_id === SCM_APP_ID || tsgInstance.app_id === PRISMA_PAE) {
			if (tsgInstance.entitlements && tsgInstance.entitlements.length > 0) {
				for (const entitlement of tsgInstance.entitlements) {
					if (
						entitlement.app_id === SCM_APP_ID &&
						(entitlement.form_factor_type === FORM_FACTOR_TYPE_NGFW ||
							entitlement.form_factor_type === FORM_FACTOR_TYPE_FLEX ||
							entitlement.form_factor_type === FORM_FACTOR_TYPE_PA)
					) {
						return true;
					}
				}
			}
		}
	}
	return false;
};

const isTenantWithCDL = (tsgInstances) => {
	for (const tsgInstance of tsgInstances) {
		if (LOGGING_SERVICE_EXTENSION_NAMES.includes(tsgInstance.app_id)) {
			return true;
		}
	}
	return false;
};

const CACHE_MAP = {
	[FEATURE_KEY]: (value) => {
		const valueObj = typeof value === 'string' ? JSON.parse(value) : value;
		if (valueObj && !valueObj?.toggles) {
			return [];
		}
		return valueObj?.toggles.map((item) => {
			return {
				name: item?.fn,
				toggle: item.t,
			};
		});
	},
	[LICENSE_KEY]: (value) => {
		if (typeof value === 'string') {
			return JSON.parse(value);
		}
		return value;
	},
	[GPEP_KEY]: (value) => {
		if (!value) return {};
		const cloudServices = typeof value === 'string' ? JSON.parse(value) : value;
		if (cloudServices?.[0] && !_.isEmpty(cloudServices[0])) {
			const enableValues = { result: { enable: {} } };
			Object.keys(cloudServices[0])?.forEach((key) => {
				const enableKey = key?.replaceAll('_', '-');
				enableValues['result']['enable'][enableKey] = cloudServices[0]?.[key] === true ? 'yes' : 'no'; // Response from 'sase/config/v1/cloud-services' api is different from  'configByPath?type=container&folder=Mobile%20Users%20Container&prefix=plugins/cloud_services&subPath=enable' .Need to update values to support existing Ui framework
			});

			return enableValues;
		}
		return {};
	},
	[NOTIFICATIONS_KEY]: (value) => {
		if (typeof value === 'string') {
			return JSON.parse(value);
		}
		return value;
	},
};

const manage_app = ({
	sparkyFramework: {
		events,
		onAuthed,
		StartupManager,
		getFrameworkServiceUrl,
		dedupPromise,
		fawkesUtils: { getRedirectURL, getAuthHeaders },
		getAuthState,
		getState,
		getMainState,
		hasTsgSupport,
		dispatch,
	},
}) => {
	const getTenantTypeFromSparky = () => {
		if (
			getAuthState()?.instances?.get('prisma_access_edition_onprem') ||
			getAuthState()?.instances?.get('prisma_access_panorama')
		) {
			return StartupManager.PANORAMA_CONFIG;
		}
		return StartupManager.FAWKES_CONFIG;
	};
	const isPaEnabled = () => {
		const instances = getAuthState()?.instances;
		if (instances.has('prisma_access_edition_onprem')) {
			return instances.has('prisma_access_panorama');
		}
		if (instances.has('bulk_config')) {
			return instances.has('prisma_access_edition');
		}
		return Boolean(instances?.has('prisma_access_edition')) && Boolean(instances?.has('prisma_access'));
	};

	const isNgfwEnabled = () => {
		const instances = getAuthState()?.instances;
		if (!instances || !instances.length) {
			return false;
		}
		if (instances.has('bulk_config')) {
			return true;
		}
		// ADI-32593 For SCM base tenant need check 'strata_cloud_manager' instance
		return (
			instances.has('prisma_access') &&
			(instances.has('strata_insights') || instances.has('strata_cloud_manager'))
		);
	};

	const dedupPromiseGenerator = ({ url, auth, getAuthHeaders, cacheKey = '', timeout = 10000 }) => {
		const cacheValue = auth.properties?.get(cacheKey);
		if (!_.isEmpty(cacheValue) && CACHE_MAP[cacheKey]) {
			return Promise.resolve(CACHE_MAP[cacheKey](cacheValue));
		}
		return dedupPromise(
			`${url}?tsgId=${auth.tsg_id}`,
			() => {
				const controller = new AbortController();
				const timeoutId = setTimeout(() => controller.abort(), timeout);
				return fetch(url, {
					signal: controller.signal,
					withCredentials: true,
					method: 'GET',
					responseType: 'json',
					headers: getAuthHeaders(),
				})
					.then(async (response) => {
						let resp = await response.json();
						clearTimeout(timeoutId);
						return resp;
					})
					.catch((err) => {
						console.log(err);
						return err;
					});
			},
			{ keepPromiseIn: 10000 },
		);
	};

	//local version set during deploy time
	let localVersion = UI_VERSION;
	let fn = async ({ auth, onTsgUnload }) => {
		//Entering the TSG
		let info = getAuthState()?.instances?.get('prisma_access');
		if (MSP_ENABLED && !info) {
			info = getAuthState()?.instances?.get('bulk_config');
		}
		let adminURL = info?.runtime_attributes?.admin_api_url;

		const type = getTenantTypeFromSparky();

		fetch(adminURL + `/api/activity/login`, {
			withCredentials: true,
			method: 'GET',
			responseType: 'json',
			headers: getAuthHeaders(),
		})
			.then(async (response) => {
				console.log('Entering the TSG ID: ', auth.tsg_id, 'TSG Name: ', auth.tsg_name);
			})
			.catch((err) => {
				console.log(err);
			});

		events?.once?.('logout', () => {
			const url = new URL(`/api/activity/logout`, adminURL).toString();
			return dedupPromise(
				`${url}?tsg_id=${auth.tsg_id}`,
				() =>
					fetch(url, {
						withCredentials: true,
						method: 'GET',
						responseType: 'json',
						headers: getAuthHeaders(),
					})
						.then(async (response) => {
							console.log('Leaving the TSG ID: ', auth.tsg_id, 'TSG Name: ', auth.tsg_name);
						})
						.catch((err) => {
							console.log(err);
						}),
				{ keepPromiseIn: 10000 },
			);
		});

		const getNotificationsInfo = dedupPromiseGenerator({
			url: new URL('/api/v1/notifications?&readStatus=false', adminURL).toString(),
			auth,
			getAuthHeaders,
			cacheKey: NOTIFICATIONS_KEY,
		});

		const getFawkesLicenseInfo = dedupPromiseGenerator({
			url: new URL('/api/extensions/gpcs/license', getRedirectURL()).toString(),
			auth,
			getAuthHeaders,
			cacheKey: LICENSE_KEY,
		});

		const getGpEPPromise = dedupPromiseGenerator({
			url: new URL(
				'/api/config/v9.2/configByPath?type=container&folder=Mobile%20Users%20Container&prefix=plugins/cloud_services&subPath=enable',
				getRedirectURL(),
			).toString(),
			auth,
			getAuthHeaders,
			cacheKey: GPEP_KEY,
		});

		const getFeaturesPromise = dedupPromiseGenerator({
			url: new URL('/api/features', adminURL).toString(),
			auth,
			getAuthHeaders,
			cacheKey: FEATURE_KEY,
		});

		/********** decouple sparky call*/
		const instances = getAuthState()?.instances;
		const extensions = [];

		instances.forEach((instance) => {
			const ext = {
				id: instance?.tenant_id,
				active: instance?.status === 'running',
				info: JSON.stringify(instance),
				internalTenantId: MSP_ENABLED
					? instances?.get('bulk_config')?.tenant_id || instances?.get('prisma_access')?.tenant_id
					: instances?.get('prisma_access')?.tenant_id,
				name: instance?.app_id,
				supportAccountId: instance?.support_account_id,
			};
			extensions.push(ext);
		});

		info.tsgInstances = instances;

		const authInfo = {
			credentials: getAuthState()?.user,
			entitlements: getAuthState()?.instances,
			extraInfo: {
				appPortal: getFrameworkServiceUrl('app_portal'),
			},
		};
		const loggedInUser = {
			email: getAuthState()?.user?.email,
			currentInstanceId: MSP_ENABLED
				? getAuthState()?.instances?.get('bulk_config')?.tenant_id ||
				  getAuthState()?.instances?.get('prisma_access')?.tenant_id
				: getAuthState()?.instances?.get('prisma_access')?.tenant_id,
			currentSupportAccountId: MSP_ENABLED
				? getAuthState()?.instances?.get('bulk_config')?.support_account_id ||
				  getAuthState()?.instances?.get('prisma_access')?.support_account_id
				: getAuthState()?.instances?.get('prisma_access')?.support_account_id,
			currentTsgId: getAuthState()?.tsg_id,
			profile: getAuthState()?.user,
			supportAccountIds: getAuthState()?.user?.supportAccountIds,
			supportaccountids: getAuthState()?.user?.supportaccountids,
			userName: getAuthState()?.user?.username,
		};
		const cluster = info?.runtime_attributes?.cluster;
		cluster.redirectURL = getRedirectURL();
		const scmFlags = () => {
			const instances = getAuthState()?.instances;
			if (!instances || !instances.length) {
				return {};
			}
			let isSCMPremiumTenant = false;
			if (instances.has(SCM_APP_ID)) {
				isSCMPremiumTenant = isSCMPremium(instances);
			} else {
				if (instances.has(STRATA_INSIGHTS) || instances.has(PRISMA_PA)) {
					isSCMPremiumTenant = true;
				}
			}
			return {
				is_scm_premium_tenant: isSCMPremiumTenant,
				is_scm_base_tenant: !isSCMPremiumTenant,
				is_tenant_with_cdl: isTenantWithCDL(instances),
			};
		};
		const tenant = {
			info,
			cluster: cluster,
			clusterName: info?.runtime_attributes?.cluster?.name,
			type,
			id: info?.tenant_id,
			tenantId: info?.tenant_id,
			supportAccountId: info?.support_account_id,
			sgId: info?.tsg_id,
			accountName: info?.support_account_name,
			iamControlled: info?.iam_controlled,
			state: info.status,
			extensions,
			pa_enabled: isPaEnabled(),
			ngfw_enabled: isNgfwEnabled(),
			scmFlags: scmFlags(),
			is_gov: info?.runtime_attributes?.is_fips_mode === 1,
		};
		loggedInUser.tenant = tenant;
		if (type !== StartupManager.PANORAMA_CONFIG) {
			await Promise.all([getFawkesLicenseInfo, getGpEPPromise, getNotificationsInfo, getFeaturesPromise])
				.then((response) => {
					const fawkesMain = {
						licenseInfo: response?.[0],
						gpEpEnabled: _.isError(response?.[1])
							? { error: response?.[1]?.message || 'error' }
							: response?.[1]?.result?.enable,
						notificationsInfo: _.isError(response?.[2]) ? [] : response?.[2],
						featuresInfo: response?.[3],
						loggedInUser,
						tenant,
						authInfo,
						tokenInfo: getState()?.libTokens,
					};
					dispatch({
						type: 'set-values',
						values: {
							fawkesMain: fawkesMain,
						},
					});
				})
				.catch((e) => console.error(e));
		} else {
			const getMigrationStatusInfo = dedupPromiseGenerator({
				url: new URL('/api/migration/v1/customer/migration-status', getRedirectURL()).toString(),
				auth,
				getAuthHeaders,
			});
			let subTenantInfo = auth?.properties?.get('netsec.subtenant');
			await Promise.all([
				getMigrationStatusInfo,
				getNotificationsInfo,
				getFeaturesPromise,
				getFawkesLicenseInfo,
				getGpEPPromise,
			])
				.then((response) => {
					const fawkesMain = {
						licenseInfo: response?.[3],
						migrationStatus: response?.[0],
						notificationsInfo: _.isError(response?.[1]) ? [] : response?.[1],
						featuresInfo: response?.[2],
						tenant,
						loggedInUser,
						tokenInfo: getState()?.libTokens,
						fqdnInfo: response?.[3],
						gpEpEnabled: _.isError(response?.[4])
							? { error: response?.[4]?.message || 'error' }
							: response?.[4]?.result?.enable,
						subTenantInfo,
					};
					dispatch({
						type: 'set-values',
						values: {
							fawkesMain: fawkesMain,
						},
					});
				})
				.catch((e) => console.error(e));
		}

		/********** decouple sparky call*/

		return Promise.all([
			import(/* webpackPreload: true, webpackChunkName: "predef" */ './predefined'),
			import(/* webpackPreload: true */ './i18n'),
		])
			.then(() => import(/* webpackPreload: true */ './routes'))
			.then((r) => r.default);
	};
	// return (params) => fn(params);
	const contributeExtension = () => {
		const extensionKey = 'aiops.posture_settings';
		const path = '/security-checks-cm';
		const subKey = `${path}-active`;
		const extensionId = 'aiops-ngfw-security-checks-cm-active';

		const postureSettingsExtensions = Registry.getExtensions(extensionKey) || [];
		const existingExtension = postureSettingsExtensions.find((ext) => ext.key === subKey);
		if (existingExtension) {
			return;
		}
		try {
			Registry.contributeExtension(extensionKey, {
				id: extensionId,
				config: {
					key: subKey,
					path,
					name: {
						defaultMessage: 'Security Checks',
					},
					component: () => import('./containers/securityChecks/index'),
					position: ['start'],
				},
			});
		} catch (error) {
			console.error('Failed to contribute extension', error);
		}
	};
	//Need to handle MSP case
	if (hasTsgSupport() && !DIRECT_DOMAIN && !MSP_ENABLED) {
		return (params) => {
			if (!getAuthState()?.instances?.isFawkesRunning) {
				// no running fawkes
				if (FALL_BACK_VERSION_ENABLED) {
					contributeExtension();
				}
				return null;
			}
			//detect the version
			let version = getAuthState()?.instances?.get('prisma_access')?.runtime_attributes?.version;
			console.log('version check - Sparky Version: ', version, ' - LocalVersion:', localVersion);
			if (localVersion === version) {
				console.log('Loading Version: ', localVersion);
				contributeExtension();
				//varsion match. Load this site
				return fn(params);
			} else {
				//return null
				return null;
			}
		};
	} else {
		//Non TSG or MSP case
		if (MSP_ENABLED) {
			//MSP tenant
			//detect the version
			return (params) => {
				let version = getAuthState()?.instances?.get('bulk_config')?.runtime_attributes?.version;
				if (!version) {
					console.log('MSP PA Root Tenant - Fetching version from prisma_access');
					version = getAuthState()?.instances?.get('prisma_access')?.runtime_attributes?.version;
				}
				console.log('MSP version check - Sparky Version: ', version, ' - LocalVersion:', localVersion);
				if (localVersion === version) {
					console.log('MSP Loading Version: ', localVersion);
					//varsion match. Load this site
					return fn(params);
				} else {
					return null;
				}
			};
		} else {
			//Non TSG or MSP case
			contributeExtension();
			console.log('Loading Cloud Management for NonTsg or Direct Domain');
			return (params) => fn(params);
		}
	}
};
// manage_app.exposes = {
//     default: {
//         responsePage: () => import('./exposedFunctions.js'),
//     }
// }
export default manage_app;
